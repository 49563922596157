<template>
  <div class="background" style="display: flex;justify-content: center;align-items: center;height: 100%">
    <a-card style="width: 470px;height: 700px" :tab-list="tabList" :active-tab-key="key" @tabChange="currentKey => onTabChange(currentKey)">
      <template v-if="key === 'login'">
        <div style="display: flex;justify-content: center">
          <div style="width: 380px;">
            <a-form-model ref="loginForm" :model="loginForm" :rules="loginFormRules">
              <a-form-model-item class="firstLabel" label="登录BattlefieldTools账号" prop="validator" :colon="false">
                <a-input-group size="large">
                  <a-row>
                    <a-col :span="24">
                      <a-input :disabled="loggedIn" class="topRadius" :maxLength="50" style="border-radius: 0" allow-clear v-model="loginForm.username" placeholder="用户名">
                        <a-icon slot="prefix" type="user"/>
                      </a-input>
                    </a-col>
                    <a-col :span="24">
                      <a-input-password :disabled="loggedIn" :maxLength="50" class="bottomRadius" v-model="loginForm.password" placeholder="密码" :visibilityToggle="loginForm.password !== ''">
                        <a-icon slot="prefix" type="lock"/>
                      </a-input-password>
                    </a-col>
                  </a-row>
                </a-input-group>
              </a-form-model-item>
              <a-form-model-item prop="rememberMe">
                <a-checkbox :disabled="loggedIn" @change="rememberMeChange">
                  保持登录
                </a-checkbox>
              </a-form-model-item>
            </a-form-model>
            <a-button v-if="!loggedIn" :disabled="processing" class="originButton" size="large" block @click="login">登录</a-button>
            <a-button v-if="loggedIn" class="originButton" size="large" block @click="$router.replace('/index')">已登录，返回首页</a-button>
<!--            <a-button class="forget" type="link">忘记密码</a-button>-->
            <div>
              <a-divider style="margin-top: 150px;margin-bottom: 5px">其他方式</a-divider>
<!--              <a-row>-->
<!--                <a-col :span="12" style="display: flex;justify-content: center">-->
<!--                  <div style="text-align: center">-->
<!--                    <a-button class="originButton" shape="circle" icon="global"/>-->
<!--                    <div>EA账号登录</div>-->
<!--                  </div>-->
<!--                </a-col>-->
<!--                <a-col :span="12" style="display: flex;justify-content: center">-->
<!--                  <div style="text-align: center">-->
<!--                    <a-button class="originButton" shape="circle" icon="global"/>-->
<!--                    <div>Remid登录</div>-->
<!--                  </div>-->
<!--                </a-col>-->
<!--              </a-row>-->
            </div>
          </div>
        </div>
      </template>

      <template v-if="key === 'register'">
        <div style="display: flex;justify-content: center">
          <div style="width: 380px;">
            <a-form-model ref="registerForm" :model="registerForm" :rules="registerFormRules">
              <a-form-model-item class="firstLabel" label="创建BattlefieldTools账号" prop="validator" :colon="false">
                <a-input-group size="large">
                  <a-row>
                    <a-col :span="24">
                      <a-input :disabled="loggedIn" class="topRadius" :maxLength="50" style="border-radius: 0" v-model="registerForm.username" placeholder="用户名">
                        <a-icon slot="prefix" type="user" />
                      </a-input>
                    </a-col>
                    <a-col :span="24">
                      <a-input-password :disabled="loggedIn" class="bottomRadius" :maxLength="50" v-model="registerForm.password" placeholder="密码" :visibilityToggle="registerForm.password !== ''">
                        <a-icon slot="prefix" type="lock" />
                      </a-input-password>
                    </a-col>
                  </a-row>
                </a-input-group>
              </a-form-model-item>
              <a-form-model-item class="secondLabel" label="补充其他信息" :colon="false">
                <a-input-group size="large">
                  <a-row>
                    <a-col :span="24">
                      <a-input :disabled="loggedIn" class="topRadius" :maxLength="50" style="border-radius: 0" v-model="registerForm.nickname" placeholder="昵称">
                        <a-icon slot="prefix" type="user" />
                      </a-input>
                    </a-col>
<!--                    <a-col :span="24">-->
<!--                      <a-input :disabled="loggedIn" class="bottomRadius" :maxLength="50" style="border-radius: 0" v-model="registerForm.personaName" placeholder="游戏ID">-->
<!--                        <a-icon slot="prefix" type="user" />-->
<!--                      </a-input>-->
<!--                    </a-col>-->
                  </a-row>
                </a-input-group>
              </a-form-model-item>
<!--              <a-form-model-item prop="lateLogin">-->
<!--                <a-checkbox :disabled="loggedIn" @change="lateLoginChange">-->
<!--                  注册完成后登录-->
<!--                </a-checkbox>-->
<!--              </a-form-model-item>-->
            </a-form-model>
            <a-button v-if="!loggedIn" :disabled="processing" class="originButton" size="large" block @click="register">注册</a-button>
            <a-button v-if="loggedIn" class="originButton" size="large" block @click="$router.replace('/index')">已登录，返回首页</a-button>
          </div>
        </div>
      </template>
    </a-card>
  </div>
</template>

<script>

import {setCookie, getToken, setToken} from "../utils/local-storage";
import {loginAuthApi, registerAuthApi} from "../request/api/auth";

export default {
  name: 'index',
  data() {
    let loginFormValidator = (rule, value, callback) => {
      if (this.loginForm.username === '') {
        callback(new Error('请输入用户名'));
      }
      else if (this.loginForm.password === '') {
        callback(new Error('请输入密码'));
      }
      else {
        callback();
      }
    };
    let registerFormValidator = (rule, value, callback) => {
      if (this.registerForm.username === '') {
        callback(new Error('请输入用户名'));
      }
      else if (this.registerForm.password === '') {
        callback(new Error('请输入密码'));
      }
      else {
        callback();
      }
    }
    return {
      loggedIn: false,
      processing: false,
      tabList: [{
        key: "login",
        tab: "登录",
      },{
        key: "register",
        tab: "注册",
      }],
      key: "login",
      loginForm: {
        username: "",
        password: "",
        captcha: "captcha",
        rememberMe: false,
      },
      registerForm: {
        username: "",
        password: "",
        nickname: "",
        lateLogin: false,
      },
      loginFormRules: {
        validator: [{ validator: loginFormValidator, trigger: 'change'}],
      },
      registerFormRules: {
        validator: [{ validator: registerFormValidator, trigger: 'change'}]
      }
    };
  },
  created() {
    this.loggedIn = null !== getToken()
    if (this.$route['query']['type']) {
      if (this.$route['query']['type'] === 'register') {
        this.key = 'register';
      }
    }
  },
  methods: {
    onTabChange(key) {
      this['key'] = key;
    },
    rememberMeChange(e) {
      this.loginForm.rememberMe = e.target.checked;
    },
    lateLoginChange(e) {
      this.registerForm.lateLogin = e.target.checked;
    },
    login() {
      this.processing = true
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          loginAuthApi(this.loginForm.username, this.loginForm.password, this.loginForm.captcha, this.loginForm.rememberMe).then((response)=>{
            let data = response['data']['data'];
            setCookie("id", data['id'])
            setCookie("username", data['username'])
            setCookie("avatar", data['avatar'])
            setCookie("nickname", data['nickname'])
            setCookie("status", data['status'])
            setToken(data['token'])
            this.$notify({
              title: '成功',
              message: '登录成功',
              type: 'success'
            });
            setTimeout(()=>{
              this.$router.replace("/center")
            },1000)
          }).catch(()=>{
            this.processing = false
          })
        } else {
          this.processing = false
        }
      });
    },
    register() {
      this.processing = true
      this.$refs['registerForm'].validate(valid => {
        if (valid) {
          registerAuthApi(this.registerForm.username, this.registerForm.password, this.registerForm.nickname).then((response)=>{
            this.$notify({
              title: '成功',
              message: '注册成功',
              type: 'success'
            });
            this.key = 'login'
          }).finally(()=>{
            this.processing = false
          })
        } else {
          this.processing = false
        }
      })
    },
  }
}
</script>

<style scoped>
.background {
  background-image: url("https://s1.ax1x.com/2023/07/03/pCr9kE6.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment:fixed;
  background-size:cover
}
::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: black;
}
::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
  color: black;
}
::v-deep .ant-tabs-ink-bar {
  background-color: #f56c2d;
}
::v-deep .ant-tabs {
  margin-left: 20px;
}
::v-deep .ant-input:focus {
  border-color: #f56c2d;
}
::v-deep .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f56c2d;
}
::v-deep .ant-input:focus {
  box-shadow: 0 0 0 2px rgba(245,108,45, 0.2);
}


::v-deep .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f56c2d;
}
::v-deep .ant-checkbox-checked::after {
  border-color: #f56c2d;
}
::v-deep .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f56c2d;
  border-color: #f56c2d;
}


::v-deep .firstLabel.ant-form-item label {
  font-size: 22px;
  font-weight: lighter;
}
::v-deep .firstLabel .ant-form-item-label {
  margin-bottom: 15px;
}
::v-deep .secondLabel .ant-form-item-label {
  font-weight: lighter;
}


::v-deep .originButton.ant-btn {
  background-color: #f56c2d;
  color: white;
  border-color: #f56c2d;
  font-size: 15px;
}
::v-deep .originButton.ant-btn:hover, .loginButton.ant-btn:focus, .loginButton.ant-btn:active, .loginButton.ant-btn.active {
  background-color: #da7e56;
  color: white;
  border-color: #da7e56;
}


::v-deep .has-error .ant-form-explain, .has-error .ant-form-split {
  color: #f56c2d;
}
::v-deep .has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover {
  border-color: #f56c2d;
}
::v-deep .ant-form-item {
  margin-bottom: 10px;
}
::v-deep .ant-form-explain {
  margin-top: 20px;
}
::v-deep .topRadius>.ant-input {
  border-radius: 4px 4px 0 0;
}
::v-deep .bottomRadius>.ant-input {
  border-radius: 0 0 4px 4px;
}
.forget.ant-btn {
  padding: 0;
}
.ant-divider-horizontal.ant-divider-with-text-center, .ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right {
  color: #a6a6a6;
}
</style>
